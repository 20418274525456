import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import PrivacyContent from "../components/privacy-policy/privacy-content";

// TODO: This page is currently disabled and deleted. In order to have the '/privacy-policy' route build again, you must remove the code currently deleting this page in the 'gatsby-node.js' file.

const PrivacyPolicyPage = ({ data }) => {
  const { privacyPolicy } = data.takeshape;

  return (
    <Layout headerClass="header--dark">
      <Seo
        title={privacyPolicy.pageTitle}
        description="This Privacy Policy describes how halfhelix.com (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site."
      />
      <div className="main--wrap">
        <PrivacyContent content={privacyPolicy.pageContent} />
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query {
    takeshape {
      getSetting {
        seoContent
      }
      privacyPolicy: getPrivacyPolicy {
        title
        pageTitle
        pageContent(format: html)
      }
    }
  }
`;
