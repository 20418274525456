import React from "react";

const PrivacyContent = ({ content }) => {
  return (
    <div className="container container--bordered container--overlay">
      <div className="privacy-content able-sense-content">
        <div className="able-sense-content__main rich-text">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyContent;
